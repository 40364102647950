(function ($, window, document) {
  // //////////
  // Global //
  // //////////

  if ($.uniform) {
    if (typeof window.uniform_fileDefaultHtml !== 'undefined')
      $.uniform.defaults.fileDefaultHtml = window.uniform_fileDefaultHtml;
    if (typeof window.uniform_fileButtonHtml !== 'undefined')
      $.uniform.defaults.fileButtonHtml = window.uniform_fileButtonHtml;
  }

  // ////////
  // Home //
  // ////////

  if ($('body#index').length) {
    // Zone SEO placée au bon endroit
    $('.ax_zone_home_seo').first().appendTo($('#home-seo')).show();
  }

  // reassurance cliquable sur tout le block
  $('#reassurance .reassurance')
    .click(function () {
      const reassurance = $(this).find('a').attr('href');
      window.location = reassurance;
    })
    .css('cursor', 'pointer');

  // ///////////
  // Product //
  // ///////////

  // @TODO 1.7 This line throw an error, can we safely delete it
  // $('.axproductsgroups_product a').tooltip();

  // //////////
  // Tunnel //
  // //////////

  if ($('body#order').length) {
    const $btnExclusive = $(
      '.cart_navigation .button-exclusive, .cart_navigation .js-button-exclusive'
    );
    // Boutons d'action principaux : toujours placer le lien de retour au panier à la fin
    $btnExclusive.appendTo($('.cart_navigation'));

    // Message sur la commande = 3 lignes de haut maxi.
    if (!$('body#order-confirmation').length && !$('body.module_paiement').length) {
      $btnExclusive.attr('href', window.baseUri);
      const $ordermsg = $('#ordermsg');
      if ($ordermsg.length) $ordermsg.find('textarea').attr('rows', 3);
    }

    const $deliveryOptions = $('.delivery_option');
    if ($deliveryOptions.length) {
      $deliveryOptions.on('click', function () {
        $('input.delivery_option_radio', this)
          .attr('checked', 'checked')
          .prop('checked', true)
          .trigger('change');
        $.uniform.update();
      });
    }
  }

  // //////////
  // Compte //
  // //////////

  if ($('.myaccount-column .list-block').length) {
    $(`.myaccount-column .list-block a[href="${window.location.href}"]`)
      .parent('li')
      .addClass('active');
  }

  /**
   * Toggles the menu sticky state depending on the current page scroll
   * nb: on Sticky mode, the menu must only be showed when the user scroll to the top
   */
  (function () {
    let prevScrollTop = window.pageYOffset;
    const header = document.getElementById('js-header');
    let hasClass = false;
    let isHover = false;
    let hasScrolledClassName = false;
    const className = 'is-hidden';
    const scrolledClassName = 'is-scrolled';
    const headerLight = document.querySelector('.header--light');

    const headerTopBanner = header.querySelector('.header-banner');

    if (headerTopBanner) {
      header.querySelector('.header__inner').style.marginTop = `${headerTopBanner.offsetHeight}px`;
      document.body.style.marginTop = `${headerTopBanner.offsetHeight}px`;
    }

    if (header) {
      header.addEventListener('mouseenter', function () {
        isHover = true;
      });

      header.addEventListener('mouseleave', function () {
        isHover = false;
      });
    }

    window.addEventListener(
      'scroll',
      function () {
        if (!header) {
          return;
        }

        const scrollTop = window.pageYOffset;
        const headerHeight = header.offsetHeight;
        // Check if the menu must be showed or hidden
        if (scrollTop > prevScrollTop && scrollTop > headerHeight && !hasClass && !isHover) {
          hasClass = true;
          header.classList.add(className);
        } else if ((scrollTop <= prevScrollTop || scrollTop < headerHeight) && hasClass) {
          header.classList.remove(className);
          hasClass = false;
        }

        if (scrollTop > 0 && !hasScrolledClassName) {
          setTimeout(() => {
            header.classList.add(scrolledClassName);
            hasScrolledClassName = true;
            if (headerLight) {
              headerLight.classList.remove('header--light');
            }
          }, 500);
        } else if (scrollTop == 0 && hasScrolledClassName) {
          header.classList.remove(scrolledClassName);
          hasScrolledClassName = false;
          if (headerLight) {
            headerLight.classList.add('header--light');
          }
        }

        prevScrollTop = scrollTop;
      },
      {
        passive: true,
      }
    );
  })();

  /* ================================
   * Popin cross-sell
   * ================================ */

  const $tabAccessories = $('#idTabAccessories');

  if ($tabAccessories.length) {
    const $crossseling = $('#layer_cart .crossseling');
    // Add a clone of the cross-sell to the popin
    $crossseling.html($tabAccessories.html());
    // Only keep 3 items in the popin cross-sell section
    $crossseling.find('li.ajax_block_product').each(function (index) {
      if (index > 2) {
        $(this).remove();
      }
    });
    $crossseling.find('img[data-src]').each(function (index, img) {
      img.src = $(this).data('src');
    });
  }

  // //////////////////////////
  // MOBILE FOOTER COLLAPSE //
  // //////////////////////////

  $('#footer .footer__title').click(function () {
    const $footerItemWrapper = $(this).parent();
    const originalHeight = 89 + $footerItemWrapper.find('ul').outerHeight(true);

    if ($footerItemWrapper.hasClass('is-open')) {
      $footerItemWrapper.removeClass('is-open');
      $footerItemWrapper.height(69);
    } else {
      $footerItemWrapper.addClass('is-open');
      $footerItemWrapper.height(originalHeight);
    }
  });
})(jQuery, window, document);
